import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1280.000000 872.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd"  transform="translate(0.000000,872.000000) scale(0.100000,-0.100000)">




<path d="M5855 7540 c10 -11 20 -20 23 -20 3 0 -3 9 -13 20 -10 11 -20 20 -23
20 -3 0 3 -9 13 -20z"/>
<path d="M6235 6326 c-55 -8 -125 -21 -155 -30 -30 -10 -86 -27 -125 -39 -77
-24 -251 -110 -332 -164 -83 -55 -202 -162 -278 -250 -99 -115 -237 -374 -272
-510 -37 -148 -47 -232 -47 -413 0 -173 10 -241 59 -400 34 -108 58 -160 130
-279 114 -189 274 -353 464 -476 105 -68 201 -110 341 -150 394 -113 824 -44
1175 190 289 193 497 517 575 895 19 92 21 128 17 285 -4 152 -9 196 -31 280
-96 364 -283 630 -593 842 -247 169 -625 258 -928 219z m45 -338 c0 -9 3 -10
8 -2 4 7 65 12 170 13 l162 2 0 -24 c0 -17 -10 -27 -39 -40 -70 -31 -114 -98
-294 -447 -64 -124 -133 -257 -154 -296 l-37 -72 111 -218 c61 -121 157 -309
214 -419 149 -292 209 -376 297 -407 23 -9 32 -18 32 -35 l0 -23 -235 0 -235
0 0 25 c0 18 5 25 19 25 11 0 28 11 39 25 11 13 21 22 24 20 2 -2 1 24 -2 58
-6 54 -24 97 -128 302 -67 132 -142 276 -167 320 l-45 80 3 -325 2 -325 60
-12 c33 -7 101 -16 150 -20 79 -7 90 -10 93 -28 1 -11 -4 -29 -12 -40 -14 -19
-28 -20 -376 -23 -198 -2 -372 -1 -386 2 -30 8 -32 36 -1 36 86 2 159 29 189
70 36 50 39 121 36 792 -3 630 -4 654 -23 693 -26 52 -72 77 -154 84 -49 5
-66 10 -66 21 0 13 50 15 379 18 226 2 382 0 388 -6 6 -6 4 -23 -5 -45 -14
-33 -18 -35 -79 -42 -34 -4 -93 -13 -130 -22 l-68 -15 0 -246 c0 -136 -3 -257
-6 -269 -4 -13 -2 -23 2 -23 5 0 31 48 57 108 26 59 93 191 148 295 126 234
148 302 118 360 -15 28 -28 37 -56 37 -18 0 -23 6 -23 25 0 14 5 25 10 25 6 0
10 -6 10 -12z m430 -209 c131 -33 231 -89 329 -188 158 -158 227 -319 238
-557 10 -204 -29 -376 -117 -526 -60 -101 -194 -232 -287 -281 -102 -53 -180
-79 -203 -67 -19 10 -60 70 -60 87 0 6 29 29 64 52 146 94 258 264 296 451 43
210 19 458 -61 620 -60 120 -172 232 -290 288 -46 22 -99 43 -116 47 -39 8
-40 15 -11 66 l22 40 60 -7 c34 -4 95 -15 136 -25z"/>
<path d="M6573 4165 c0 -8 4 -12 9 -9 5 3 6 10 3 15 -9 13 -12 11 -12 -6z"/>
<path d="M10510 5900 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8
0 -15 -4 -15 -10z"/>
<path d="M10500 5760 c-13 -9 -13 -10 0 -9 8 0 24 4 35 9 l20 9 -20 0 c-11 0
-27 -4 -35 -9z"/>
<path d="M9390 5750 c0 -5 7 -7 15 -4 8 4 15 8 15 10 0 2 -7 4 -15 4 -8 0 -15
-4 -15 -10z"/>
<path d="M9368 5633 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M10305 5520 c-13 -6 -15 -9 -5 -9 8 0 22 4 30 9 18 12 2 12 -25 0z"/>
<path d="M10288 5423 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M10280 5383 l-25 -15 25 6 c14 3 31 6 38 6 7 0 10 5 7 10 -8 13 -14
12 -45 -7z"/>
<path d="M9279 5338 c-8 -29 -5 -38 14 -38 21 1 21 1 3 11 -15 9 -16 13 -5 20
10 6 11 9 2 9 -7 0 -13 -1 -14 -2z"/>
<path d="M3208 5293 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M10138 5133 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M9208 5113 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M10115 5010 c-8 -13 5 -13 25 0 13 8 13 10 -2 10 -9 0 -20 -4 -23
-10z"/>
<path d="M9198 5003 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M10045 4770 c-13 -6 -15 -9 -5 -9 8 0 22 4 30 9 18 12 2 12 -25 0z"/>
<path d="M10058 4743 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M9168 4673 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M10050 4670 l-25 -8 28 -1 c15 -1 27 4 27 9 0 6 -1 10 -2 9 -2 -1
-14 -5 -28 -9z"/>
<path d="M2940 2635 c0 -418 -1 -415 106 -415 l44 0 2 185 3 185 61 -93 c105
-157 187 -252 235 -271 49 -20 98 -15 121 12 29 33 26 46 -17 73 -48 31 -109
100 -194 223 l-62 88 56 75 c31 41 97 121 146 179 l90 104 -88 0 -88 0 -57
-61 c-32 -34 -86 -102 -120 -151 -35 -49 -68 -97 -75 -106 -10 -14 -12 8 -13
110 0 77 -5 137 -12 153 -15 33 -56 55 -103 55 l-35 0 0 -345z"/>
<path d="M3798 2963 c-52 -58 -149 -285 -203 -473 -64 -222 -57 -270 35 -270
55 0 49 -9 75 113 l16 77 154 0 153 0 7 -42 c3 -24 9 -58 12 -76 8 -48 44 -72
109 -72 37 0 54 4 54 13 -1 70 -92 395 -152 542 -53 129 -78 168 -121 188 -47
23 -119 22 -139 0z m107 -186 c26 -61 85 -224 85 -238 0 -5 -52 -9 -116 -9
l-116 0 7 37 c13 69 95 263 112 263 2 0 15 -24 28 -53z"/>
<path d="M4467 2948 c-69 -90 -201 -437 -228 -600 -11 -68 -11 -79 4 -102 15
-22 25 -26 67 -26 53 0 53 0 65 75 3 22 11 57 16 78 l10 37 149 0 c82 0 151
-4 154 -8 3 -5 10 -37 15 -73 14 -85 40 -109 117 -109 l55 0 -6 38 c-27 161
-116 452 -184 597 -44 95 -72 117 -152 123 -54 4 -57 3 -82 -30z m126 -195
c19 -43 43 -105 52 -138 10 -33 20 -66 22 -72 4 -10 -22 -13 -111 -13 -106 0
-116 2 -116 18 0 40 95 282 110 282 4 0 23 -35 43 -77z"/>
<path d="M4960 2634 c0 -243 3 -352 12 -367 18 -35 40 -46 90 -47 l48 0 2 186
3 187 51 -79 c28 -44 84 -121 124 -173 56 -72 82 -98 117 -113 51 -22 101 -19
125 10 29 33 26 46 -17 73 -45 29 -134 133 -208 243 l-46 69 61 79 c33 44 99
125 146 180 l85 100 -93 -3 -93 -4 -53 -59 c-29 -32 -86 -105 -126 -163 l-73
-104 -5 133 c-3 83 -10 142 -18 156 -16 27 -50 42 -98 42 l-34 0 0 -346z"/>
<path d="M5793 2923 c-72 -114 -175 -386 -208 -556 -15 -73 -15 -89 -4 -113
13 -26 20 -29 66 -32 58 -3 52 -13 78 111 l16 77 154 0 154 0 6 -47 c10 -73
24 -111 48 -128 25 -18 127 -21 127 -4 0 70 -87 384 -151 544 -68 169 -103
205 -204 205 -44 0 -46 -1 -82 -57z m134 -150 c26 -59 83 -220 83 -235 0 -4
-52 -8 -115 -8 -88 0 -115 3 -115 13 0 40 100 287 116 287 3 0 17 -26 31 -57z"/>
<path d="M6302 2625 l3 -357 28 -24 c21 -18 40 -24 72 -24 l45 0 2 186 3 186
67 -99 c158 -233 224 -294 305 -278 28 5 74 45 73 64 0 3 -19 17 -41 31 -22
14 -61 48 -85 77 -55 64 -164 223 -164 238 0 11 137 185 238 304 l44 51 -77 0
c-47 0 -87 -6 -104 -14 -31 -17 -110 -110 -197 -236 l-59 -85 -5 142 c-6 169
-13 182 -100 191 l-51 5 3 -358z"/>
<path d="M6970 2634 c0 -243 3 -352 12 -367 18 -35 40 -46 91 -47 l47 0 0 351
0 351 -29 29 c-24 24 -38 29 -75 29 l-46 0 0 -346z"/>
<path d="M7310 2967 l-55 -12 0 -315 c0 -357 1 -361 78 -402 37 -20 57 -23
162 -23 102 0 127 3 167 22 142 65 229 231 215 410 -12 158 -103 274 -247 317
-63 19 -236 20 -320 3z m288 -136 c82 -42 114 -104 120 -226 4 -84 2 -94 -25
-145 -41 -77 -102 -115 -188 -115 -99 0 -95 -11 -95 266 l0 239 75 0 c55 0 86
-5 113 -19z"/>
<path d="M8167 2948 c-57 -75 -136 -263 -193 -458 -64 -216 -57 -270 36 -270
l49 0 11 58 c7 31 15 73 19 92 l7 35 154 0 155 0 12 -70 c17 -91 41 -115 119
-115 51 0 54 1 50 23 -3 12 -15 69 -26 127 -29 140 -111 388 -163 491 -48 94
-81 119 -159 119 -41 0 -50 -4 -71 -32z m122 -189 c26 -59 81 -211 81 -225 0
-2 -52 -4 -115 -4 -105 0 -115 2 -115 18 0 41 94 282 110 282 4 0 22 -32 39
-71z"/>
<path d="M8644 2970 c-31 -12 -54 -49 -54 -87 l0 -33 110 0 110 0 0 -281 c0
-285 3 -308 39 -336 9 -7 37 -13 63 -13 l48 0 2 313 3 312 75 3 c41 1 85 4 98
7 28 7 62 60 62 98 l0 27 -267 -1 c-148 0 -278 -4 -289 -9z"/>
<path d="M9451 2954 c-67 -84 -192 -409 -227 -588 -16 -79 -16 -92 -3 -115 12
-22 23 -26 66 -29 58 -3 52 -13 78 111 l16 77 154 0 153 0 7 -42 c3 -24 9 -58
12 -76 8 -48 44 -72 110 -72 l55 0 -7 43 c-14 89 -76 320 -116 429 -87 238
-119 279 -219 286 -53 4 -58 2 -79 -24z m103 -151 c17 -35 81 -209 90 -245 l6
-28 -115 0 c-103 0 -115 2 -115 18 0 38 97 282 112 282 4 0 14 -12 22 -27z"/>
<path d="M4403 2002 c-1 -20 -14 -75 -30 -122 l-28 -86 37 -1 c28 -2 36 1 31
12 -3 8 0 37 7 65 11 45 15 50 40 50 16 0 30 -6 33 -12 6 -18 14 -8 23 27 7
28 7 29 -10 12 -10 -9 -29 -17 -43 -17 -22 0 -24 3 -19 23 4 12 9 34 12 50 6
23 12 27 39 27 44 0 65 -9 65 -27 0 -8 5 -11 10 -8 6 3 10 15 10 26 0 17 -8
19 -87 19 l-88 0 -2 -38z"/>
<path d="M4744 2017 c-10 -12 -53 -63 -94 -112 -41 -50 -81 -95 -88 -100 -11
-9 -8 -11 11 -11 14 0 23 5 21 11 -4 9 58 91 122 164 7 8 1 -134 -6 -154 -7
-17 -3 -20 24 -22 l31 -3 3 125 c2 69 2 125 0 125 -3 0 -13 -10 -24 -23z"/>
<path d="M4917 2022 c-25 -28 -21 -67 12 -104 43 -51 54 -75 41 -98 -21 -38
-100 -14 -100 30 0 21 -16 -1 -23 -30 -6 -24 -5 -25 59 -28 83 -4 114 13 114
63 0 25 -10 46 -40 80 -42 49 -47 64 -28 83 20 20 55 13 77 -15 15 -21 20 -24
21 -11 0 9 2 22 5 30 4 10 -10 14 -58 16 -51 3 -66 0 -80 -16z"/>
<path d="M5143 2023 c-3 -10 -8 -22 -11 -28 -3 -5 3 -3 14 6 10 9 29 20 42 23
17 6 22 3 22 -11 0 -23 -45 -174 -59 -200 -9 -18 -6 -20 29 -21 30 -1 38 1 34
12 -8 22 49 218 65 224 8 3 22 -5 33 -17 10 -13 18 -19 18 -13 0 6 3 17 6 26
5 14 -6 16 -90 16 -83 0 -98 -2 -103 -17z"/>
<path d="M5574 1977 c-29 -34 -75 -90 -104 -124 -44 -54 -49 -63 -31 -63 14 0
21 6 21 19 0 10 14 33 30 51 17 18 30 35 30 38 0 10 53 62 63 62 4 0 6 -9 2
-20 -3 -11 -7 -49 -9 -84 l-2 -65 31 1 30 1 0 124 c0 67 -2 123 -5 122 -3 0
-28 -28 -56 -62z"/>
<path d="M5788 2015 c2 -14 8 -23 12 -20 4 2 28 -41 54 -96 26 -56 49 -99 52
-97 8 9 54 172 51 183 -2 5 -9 -14 -16 -43 -7 -28 -16 -54 -21 -57 -4 -2 -24
31 -44 75 -33 73 -39 80 -65 80 -25 0 -28 -3 -23 -25z"/>
<path d="M5957 2020 c3 -12 6 -19 7 -18 1 2 8 11 15 21 12 15 11 17 -7 17 -15
0 -19 -5 -15 -20z"/>
<path d="M6086 2029 c9 -11 4 -39 -17 -112 -15 -54 -33 -105 -40 -113 -10 -12
-3 -14 42 -14 45 0 50 2 36 13 -16 11 -15 19 13 116 16 57 31 106 34 108 2 3
13 2 24 -1 23 -8 42 -46 42 -84 0 -40 -41 -115 -70 -129 l-25 -13 24 0 c31 0
84 36 105 71 24 41 21 103 -8 136 -21 24 -32 28 -97 31 -61 4 -72 2 -63 -9z"/>
<path d="M6487 2021 c5 -21 -43 -198 -59 -218 -8 -10 -2 -13 25 -13 32 0 35 3
40 33 2 17 15 71 28 120 18 66 28 87 41 87 42 0 42 -71 -1 -98 -14 -9 -27 -16
-28 -17 -1 0 6 -28 16 -60 16 -53 22 -60 46 -63 23 -3 27 -1 20 15 -31 78 -38
107 -25 111 22 8 60 50 60 67 0 35 -37 55 -104 55 -58 0 -64 -2 -59 -19z"/>
<path d="M6775 2031 c5 -8 -38 -169 -60 -223 -6 -14 3 -16 79 -16 86 1 96 5
109 41 6 16 4 15 -21 -2 -31 -23 -97 -37 -106 -23 -3 5 1 33 10 61 12 43 19
51 39 51 13 0 28 -7 33 -16 8 -14 11 -11 21 16 15 44 14 46 -8 26 -11 -10 -30
-16 -43 -14 -24 3 -24 4 -10 51 12 44 15 47 47 47 18 0 40 -7 48 -15 16 -15
27 -10 27 12 0 10 -21 13 -86 13 -51 0 -83 -4 -79 -9z"/>
<path d="M7055 2030 c6 -10 -39 -182 -55 -212 -6 -10 -10 -20 -10 -22 0 -2 36
-5 79 -5 72 -1 82 1 95 21 24 33 19 42 -9 19 -30 -23 -83 -37 -95 -25 -10 11
24 144 49 197 l18 37 -39 0 c-22 0 -37 -4 -33 -10z"/>
<path d="M7315 2030 c6 -10 -39 -191 -56 -222 -8 -15 -5 -17 25 -16 27 1 34 5
34 22 0 33 54 210 66 218 6 4 -8 8 -32 8 -26 0 -41 -4 -37 -10z"/>
<path d="M7525 1934 c-49 -59 -95 -113 -102 -120 -18 -18 -16 -24 7 -24 12 0
17 5 14 14 -4 10 71 111 123 166 3 3 3 -34 0 -83 -3 -48 -2 -87 2 -88 49 -6
46 -14 49 118 2 68 2 123 0 123 -2 0 -44 -48 -93 -106z"/>
<path d="M7777 2022 c4 -18 -38 -176 -57 -212 -10 -19 -8 -20 37 -19 26 1 40
3 31 6 -22 5 -22 2 -2 69 11 38 20 54 33 54 46 0 57 -66 16 -98 -34 -27 -32
-31 12 -21 64 14 84 71 39 107 l-25 19 33 16 c41 19 52 51 25 78 -15 15 -33
19 -83 19 -57 0 -64 -2 -59 -18z m99 -7 c10 -26 -6 -65 -31 -77 -34 -15 -38
-8 -24 46 8 35 16 46 31 46 10 0 21 -7 24 -15z"/>
<path d="M8056 2026 c5 -13 -40 -185 -57 -218 -8 -14 -4 -17 29 -16 34 0 38 3
35 22 -5 24 41 192 58 213 9 10 3 13 -29 13 -31 0 -40 -3 -36 -14z"/>
<path d="M8317 2022 c4 -18 -38 -176 -57 -212 -10 -19 -8 -20 36 -20 40 0 45
2 34 15 -9 11 -9 25 1 65 11 45 16 50 40 50 16 0 32 -8 38 -17 9 -16 10 -16
11 -1 0 9 3 25 6 35 6 15 4 16 -7 6 -19 -19 -73 -17 -66 1 3 8 9 31 13 50 6
34 9 36 45 36 28 0 42 -6 53 -22 9 -12 16 -17 16 -11 0 6 3 18 6 27 5 14 -6
16 -84 16 -82 0 -90 -2 -85 -18z"/>
<path d="M5760 1898 c-13 -46 -28 -89 -33 -95 -7 -10 -4 -13 11 -13 15 0 19 5
15 19 -2 10 5 51 17 91 12 39 20 74 17 76 -2 3 -14 -33 -27 -78z"/>
<path d="M4666 1882 c-3 -5 1 -9 9 -9 8 0 12 4 9 9 -3 4 -7 8 -9 8 -2 0 -6 -4
-9 -8z"/>
<path d="M7517 1879 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M8166 1841 c-5 -7 -27 -21 -50 -31 l-41 -19 42 0 c33 -1 45 4 53 20
13 23 9 51 -4 30z"/>
<path d="M8400 1819 l-55 -28 43 1 c37 1 45 6 58 30 8 15 13 27 12 27 -2 -1
-28 -15 -58 -30z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
